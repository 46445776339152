<template>
  <b-modal
    hide-footer
    no-close-on-backdrop
    hide-header
    no-fade
    v-model="modalShow"
    size="lg"
  >
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div class="item active pb-5">
          <div class="carousel-content text-center">
            <img src="../../assets/sol-media-01.png" alt="" class="my-5" />
            <div class="heading">Learn</div>
            <div class="subheading">
              Hold your horses .. we are learning for your
              business data.
            </div>
          </div>
        </div>

        <div class="item pb-5">
          <div class="carousel-content text-center">
            <img src="../../assets/sol-media-03.png" alt="" class="my-5" />
            <div class="heading">Train</div>
            <div class="subheading">
              We are training our engine for your business data.
            </div>
          </div>
        </div>
        <div class="item pb-5">
          <div class="carousel-content text-center">
            <img src="../../assets/sol-media-02.png" alt="" class="my-5" />
            <div class="heading">Test</div>
            <div class="subheading">
              We are testing our Prediction for your business data.
            </div>
          </div>
        </div>
      </div>

      <!-- Indicators -->
      <ol class="carousel-indicators">
        <li data-target="#myCarousel" class="active"></li>
        <li data-target="#myCarousel"></li>
        <li data-target="#myCarousel"></li>
        <li
          data-target="#myCarousel"
          ref="slideNext"
          style="display: none"
          data-slide="next"
        ></li>
      </ol>
    </div>
    <div class="progress-container">
      <div
        class="progress-value"
        :style="{
          left: progressValue - 4 + '%',
        }"
      >
        {{ progressValue }} %
      </div>
      <b-progress
        height="6px"
        variant="dark"
        :value="progressValue"
        max="100"
        class="mb-3 progress"
      ></b-progress>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "SolutionModal",
  props: {
    duration: {
      type: Number,
      required: false,
      default: 300,
    },
  },
  data() {
    return {
      modalShow: false,
      carInterval: null,
      totalSlides: 3,
      progressValue: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.carInterval);
  },
  methods: {
    open() {
      this.modalShow = true;
      // let count = 1;

      this.carInterval = setInterval(() => {
        // if (count < this.totalSlides) {
        this.progressValue++;
        if (this.progressValue === 30) {
          // count++;
          this.moveToNext();
        } else if (this.progressValue === 70) {
          // count++;
          this.moveToNext();
        } else if (this.progressValue === 100) {
          clearInterval(this.carInterval);
          this.$emit("progressDone");
          // this.$router.push("/solutions/results");
        }

        // this.moveToNext();
        // if (count === 1) {
        //   this.progressValue = 70;
        // } else if (count === 2) {
        //   this.progressValue = 99;
        // }
        // count++;
        // } else {
        //   clearInterval(this.carInterval);
        //   this.$router.push("/solutions/results");
        // }
      }, this.duration);
    },
    moveToNext() {
      this.$refs.slideNext.click();
    },
  },
};
</script>
<style>
.modal-dialog {
  margin-top: 0px !important;
}
</style>
<style lang="css" scoped>
.modal-dialog {
  margin: 0 auto !important;
}

.model-content {
  border-radius: 0 !important;
}

::v-deep .modal-backdrop {
  opacity: 0.9;
}

::v-deep .progress-bar {
  transition: unset;
}

.wrapper-carousel {
  text-align: center;
}

/* .carousel-content {
  position: absolute;
  top: 630px;
  left: -75px;
  width: 452px;
  height: 100px;
  font-family: ProximaNovaBold;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
} */

.carousel-inner .item img {
  /* position: relative; */
  /* height: 700px; */
  height: 286px;
  width: 286px;
  /* left: 20%; */
}

/* .carousel-indicators {
  position: absolute;
  top: 739px;
  left: -100px;
} */

.carousel-indicators {
  margin: 0px auto;
}

.carousel-indicators .active {
  background-color: #222a37;
  width: 32px;
  height: 8px;
  object-fit: contain;
  border-radius: 4px;
  margin-left: 6px;
}

.carousel-indicators li {
  height: 8px;
  width: 8px;
  border: 2px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
  border: 1px solid #222a37;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.carousel-inner .item .subheading {
  margin: 8px 72px 48px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #9aafd4;
}

.carousel-inner .item .heading {
  /* margin: 23px 281px 8px; */
  font-family: ProximaNovaBold;
  font-size: 32px;
  line-height: 1.56;
  text-align: center;
  color: #222a37;
}

.progress-container {
  position: relative;
  margin: 100px 26px 27px 26px;
}
.progress-value {
  position: relative;
  color: #050505;
  width: 30px;
}

.progress-value::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #343a40;
  right: 0%;
  height: 14px;
  top: 7px;
}
</style>
